import { TableCell, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns/fp';

import { AffiliateTransaction } from '@calo/dashboard-types';

import { caloTheme } from '../../theme/calo';

interface TransactionRowMobileProps {
  affiliateTransaction: AffiliateTransaction;
}

const TransactionRowMobile = ({ affiliateTransaction }: TransactionRowMobileProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: caloTheme.palette.neutral900,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '14px'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none'
    }
  }));

  const transactionDate = format('dd/MM/yyyy HH:mm a')(affiliateTransaction.date);

  return (
    <TableRow key={affiliateTransaction.id} hover>
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          textOverflow: 'ellipsis',
          paddingLeft: '10px',
          paddingRight: '0px'
        }}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '19px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            textOverflow: 'ellipsis',
            fontFamily: caloTheme.typography.fontFamily
          }}
        >
          {affiliateTransaction.type.toLocaleUpperCase()}
        </Typography>
      </StyledTableCell>
      <StyledTableCell sx={{ paddingLeft: '10px', paddingRight: '0px' }}>
        {' '}
        {affiliateTransaction.userName}
      </StyledTableCell>
      <StyledTableCell>{transactionDate}</StyledTableCell>
      <StyledTableCell>
        {affiliateTransaction.commissionInOriginalCurrency
          ? `${affiliateTransaction.commissionInOriginalCurrency} ${affiliateTransaction.originalCurrency}`
          : 'N/A'}
      </StyledTableCell>
    </TableRow>
  );
};
export default TransactionRowMobile;

import { create } from 'zustand';

interface AuthStore {
  userId?: string;
  setUserId: (userId?: string) => void;
}

export const useAuthStore = create<AuthStore>()((set) => ({
  userId: undefined,
  setUserId: (userId) => set({ userId })
}));

import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { Affiliate, AffiliateTransaction, AffiliateTransactionType, GetAffiliateTransactionsRes } from '@calo/dashboard-types';

import client from '../../lib/client';
import { isSameMonth } from 'date-fns/fp';
import { convertCurrency } from 'src/lib/utils';

interface useAffiliateTransactionsProps {
  affiliateId: string;
}

const useAffiliateTransactions = ({ affiliateId }: useAffiliateTransactionsProps) => {
  const { data, hasNextPage, fetchNextPage, refetch, isLoading } = useInfiniteQuery<GetAffiliateTransactionsRes>(
    [`/v1/affiliates/${affiliateId}/transactions`],
    async ({ pageParam, queryKey }) => {
      const { data } = await client.get(queryKey[0] as string, {
        params: {
          ...(pageParam && {
            cursor: pageParam
          })
        }
      });
      return data;
    },
    {
      getNextPageParam: (data) => data.meta.cursor
    }
  );

  const transactions = useMemo(
    () =>
      (data?.pages || []).reduce<AffiliateTransaction[]>((res, r) => {
        res = [...res, ...(r.transactions || [])];
        return res;
      }, []),
    [data]
  );

  const calculateEarnings = (affiliate: Affiliate) => {
    let earnings = 0;

    for (const transaction of transactions) {
      if (transaction.type === AffiliateTransactionType.redeemed) {
        if (isSameMonth(new Date())(new Date(transaction.date))) {
          const commissionInOriginalCurrency = transaction.commissionInOriginalCurrency;
          const originalCurrency = transaction.originalCurrency;
          if (commissionInOriginalCurrency && originalCurrency && affiliate) {
            earnings += convertCurrency(commissionInOriginalCurrency, originalCurrency, affiliate.currency);
          }
        } else {
          break;
        }
      }
    }
    return earnings;
  }

 

  return {
    transactions,
    transactionsLoading: isLoading,
    calculateEarnings,
    refetchAffiliate: refetch,
    hasNextPage,
    fetchNextPage
  };
};

export default useAffiliateTransactions;

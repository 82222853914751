import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Auth from '@aws-amplify/auth';

import { useAuthStore } from '../lib/store/authStore';
import Icon from './Icon';

const TopBar = () => {
  const { userId, setUserId } = useAuthStore();
  const naviagte = useNavigate();

  const signOut = async () => {
    await Auth.signOut();
    setUserId();
    naviagte('/');
  };

  return (
    <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
      <AppBar sx={{ background: '#FFFFFF', padding: '8px 32px 8px 32px', height: '60px', boxShadow: 'none' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ textAlign: 'start' }}>
            <Icon name="calo" />
          </Box>
          {userId && (
            <Button onClick={signOut}>
              <Icon name='signOut' />
            </Button>
          )}
        </Box>
      </AppBar>
      <Toolbar></Toolbar>
    </Box>
  );
};

export default TopBar;

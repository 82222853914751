import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useAuthStore } from './lib/store/authStore';
import { caloTheme } from './theme/calo';
import SignInForm from './views/signIn/SignInForm';
import HomePage from './views/HomePage/HomePage';
import { useAuth } from './hooks/useAuth';

function App() {
  const queryClient = new QueryClient();
  const { userId, setUserId } = useAuthStore();

  useAuth({ userId, setUserId });

  return (
    <ThemeProvider theme={caloTheme}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          {userId ? (
            <Route path="/:id" element={<HomePage userId={userId} />} />
          ) : (
            <Route path="/" element={<SignInForm />} />
          )}
        </Routes>
        <ToastContainer position="top-right" />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

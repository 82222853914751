import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { addMonths, format, startOfMonth } from 'date-fns/fp';

import useAffiliate from './useAffiliate';
import { caloTheme } from '../../theme/calo';
import CaloLoader from '../../components/CaloLoader';
import TransactionRowMobile from './TransactionRowMobile';
import useAffiliateTransactions from './useAffiliateTransactions';
interface TransactionsListProps {
  userId: string;
}

const TransactionsCardMobile = ({ userId }: TransactionsListProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      border: 'none'
    },
    [`&.${tableCellClasses.body}`]: {
      border: 'none',
      justifyContent: 'space-between'
    }
  }));

  const { affiliate } = useAffiliate({
    affiliateId: userId
  });

  const { transactions, transactionsLoading, hasNextPage, fetchNextPage, calculateEarnings } = useAffiliateTransactions({
    affiliateId: userId
  });

  const nextPaymentDate = format('dd/MM/yyyy')(startOfMonth(addMonths(1)(new Date())));
  const earnings = affiliate && calculateEarnings(affiliate);


  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          border: 'none',
          borderRadius: '8px',
          paddingBottom: '4px',
          [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
            flexDirection: 'column'
          }
        }}
      >
        <Box overflow="auto">
          <Box
            display={'flex'}
            flexDirection="row"
            justifyContent={'space-between'}
            sx={{
              padding: 2,
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.mobile)]: {
                flexDirection: 'column'
              }
            }}
            // width="100%"
          >
            <Stack display={'flex'} flexDirection={'row'} alignItems="start" justifyContent={'space-between'}>
              <Typography
                variant="h6"
                sx={{
                  pb: 2,
                  fontFamily: caloTheme.typography.fontFamily,
                  fontWeight: 600,
                  lineHeight: '23px',
                  fontSize: '33px'
                }}
              >
                Hi, {affiliate?.name} 👋
              </Typography>
            </Stack>
          </Box>
          <Box
            overflow="auto"
            width="100%"
            sx={{
              display: 'flex'
            }}
          >
            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography>Coupon Users:</Typography> {affiliate?.totalUsersAttracted}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography>Earnings:</Typography> ${earnings}
            </Typography>

            <Divider orientation="vertical" flexItem />

            <Typography
              variant="h6"
              sx={{
                pb: 2,
                fontFamily: caloTheme.typography.fontFamily,
                fontWeight: 600,
                lineHeight: '23px',
                marginX: 2
              }}
            >
              <Typography>Next Payment:</Typography> {nextPaymentDate}
            </Typography>
          </Box>
          {transactionsLoading ? (
            <Stack sx={{ width: '100%', justifyContent: 'center' }}>
              <CaloLoader />
            </Stack>
          ) : (
            <Table
              sx={{
                marginY: '4px'
              }}
            >
              <TableHead
                style={{ borderRadius: '8px' }}
                sx={{
                  backgroundColor: caloTheme.palette.neutral50,
                  color: 'black',
                  flexWrap: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                  borderRadius: '8px'
                }}
              >
                <TableRow
                  sx={{
                    backgroundColor: caloTheme.palette.neutral50,
                    color: 'black',
                    width: '100%',
                    flexWrap: 0,
                    justifyContent: 'space-between'
                  }}
                >
                  <StyledTableCell
                    style={{
                      borderTopLeftRadius: '8px',
                      borderBottomLeftRadius: '8px'
                    }}
                  >
                    Trans. Type
                  </StyledTableCell>
                  <StyledTableCell>User</StyledTableCell>
                  <StyledTableCell>Transaction Date</StyledTableCell>
                  <StyledTableCell>Commission</StyledTableCell>
                </TableRow>
              </TableHead>
              {transactions?.length > 0 ? (
                <TableBody>
                  {transactions?.map((transaction) => (
                    <TransactionRowMobile affiliateTransaction={transaction} key={transaction.id} />
                  ))}
                </TableBody>
              ) : (
                <Typography>NO TRANSACTIONS!</Typography>
              )}
            </Table>
          )}
        </Box>
        {hasNextPage && (
          <Box display={'flex'} justifyContent="center">
            <Button onClick={() => fetchNextPage()}>Load Older Transactions</Button>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default TransactionsCardMobile;

import TopBar from '../../components/TopBar';
import { useDocumentMedia } from '../../hooks/useDocumentMedia';
import TransactionsCard from '../transactionsPage/TransactionsCard';
import TransactionsCardMobile from '../transactionsPage/TransactionsCardMobile';

interface HomePageProps {
  userId: string;
}

const HomePage = ({ userId }: HomePageProps) => {
  const { isMobile, isTablet } = useDocumentMedia();

  return (
    <>
      <TopBar />
      {isMobile || isTablet ? <TransactionsCardMobile userId={userId} /> : <TransactionsCard userId={userId} />}
    </>
  );
};

export default HomePage;

import Auth from '@aws-amplify/auth';
import axios, { AxiosError } from 'axios';
import { stringify } from 'qs';
import { toast } from 'react-toastify';

import { useAuthStore } from './store/authStore';

interface ErrorData {
  message: string;
  title: string;
}

const createAxios = (baseURL: any) => {
  const newInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    },

    paramsSerializer: (params) => stringify(params)
  });

  newInstance.interceptors.request.use(
    async (options) => {
      try {
        const session = await Auth.currentSession();
        if (options.headers) {
          options.headers['Authorization'] = `Bearer ${session.getIdToken().getJwtToken()}`;
        }

        return options;
      } catch {
        return {
          ...options,
          cancelToken: new axios.CancelToken((cancel) => cancel())
        };
      }
    },
    (error) => Promise.reject(error)
  );

  newInstance.interceptors.response.use(
    (res) => res,
    (error: AxiosError<ErrorData>) => {
      if (error.response && error.response.status === 409) {
        toast(error.response?.data.message || 'Conflict Error', {
          type: 'error'
        });
      } else if (error.response && error.response.status === 422) {
        toast(error.response?.data.message || 'Input Error', {
          type: 'error'
        });
      } else if (error.response && error.response.status === 404) {
        toast(error.response?.data.message || 'Affiliate Not Found', {
          type: 'error'
        });
        const { setUserId } = useAuthStore.getState();
        Auth.signOut().then(() => setUserId());
      } else {
        toast(error.response?.data.message || 'Server Error', {
          type: 'error'
        });
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
};

const client = createAxios(process.env.REACT_APP_AFFILIATE_SERVICE_API_URL);

export default client;

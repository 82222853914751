import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface LoadingCircleProps {
  text?: string;
}

const LoadingCircle = ({ text }: LoadingCircleProps) => (
  <Box sx={{ display: 'flex' }}>
    <CircularProgress color='success' />
    {text && <p>&nbsp; {text}</p>}
  </Box>
)

export default LoadingCircle;
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { useAuthStore } from '../store/authStore';

type ChallengeType = 'OTP' | 'TOKEN';

const getCaptchaToken = async () => {
  try {
    const captchaToken = await new Promise<string>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      grecaptcha.enterprise.ready(async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action: 'login'
        });
        resolve(token);
      });
    });

    return captchaToken;
  } catch {
    return 'ERROR';
  }
};

export const signIn = async (userIdOrPhoneNumber: string, challengeType: ChallengeType = 'OTP') => {
  const userName = userIdOrPhoneNumber;
  const captcha = await getCaptchaToken();
  const signInCognitoUser = await Auth.signIn(userName, undefined, {
    captcha
  });
  // This custom challenge answer serves to inform the backend of the challenge type we're using.
  // After this is API call done, the user is still not signed in
  const cognitoUser = await Auth.sendCustomChallengeAnswer(signInCognitoUser, 'PROVIDE_CHALLENGE_TYPE', {
    challengeType
  });

  return {
    cognitoUser,
    challenge: cognitoUser.challengeParam.challenge,
    resendIn: parseInt(cognitoUser.challengeParam.resendIn)
  };
};

export const verifyCode = async (code: string, cognitoUser: CognitoUser, challengeType: ChallengeType = 'OTP') => {
  try {
    await Auth.sendCustomChallengeAnswer(cognitoUser, code.trim(), { challengeType });
    const { setUserId } = useAuthStore.getState();
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    setUserId(authenticatedUser.attributes.sub);
    return authenticatedUser;
  } catch {
    throw new Error('INVALID_CODE');
  }
};

export const resendCode = async (cognitoUser: CognitoUser) => {
  const response = await Auth.sendCustomChallengeAnswer(cognitoUser, 'RESEND_OTP', { challengeType: 'RESEND_OTP' });
  return {
    resendIn: parseInt(response.challengeParam.resendIn)
  };
};

export const getUserAttributes = (user: CognitoUser) =>
  new Promise<Record<string, string>>((resolve, reject) => {
    user.getUserAttributes((error, result) => {
      if (error) {
        return reject(error);
      }
      if (!result) {
        return resolve({});
      }
      resolve(
        result.reduce((res, attr) => {
          res[attr.getName()] = attr.getValue();
          return res;
        }, {} as Record<string, string>)
      );
    });
  });

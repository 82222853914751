import { Box, Button, Typography } from '@mui/material';

interface ResendButtonProps {
  resendIn: string | null | 0;
  handleResend: () => void;
}

const ResendButton = ({ resendIn, handleResend }: ResendButtonProps) => (
  <>
    {resendIn ? (
      <Box>
        <Typography>Resend code in {resendIn}</Typography>
      </Box>
    ) : (
      <Button
        sx={{ mt: 3, mb: 2 }}
        variant="contained"
        onClick={() => handleResend()}
        disabled={!!resendIn}
      >
        Resend
      </Button>
    )}
  </>
);

export default ResendButton;

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { MuiTelInput } from 'mui-tel-input';

import useSignInForm from './useSignInForm';
import LoadingCircle from '../../components/LoadingCircle';
import ResendButton from '../../components/ResendButton';
import { countryCodes } from '../../lib/countries';
import { removeAllWhiteSpacesAndDashes } from '../../lib/utils';
import Icon from 'src/components/Icon';

const SignInForm = () => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    submitForm,
    isValid,
    isSubmitting,
    loading,
    handleResend,
    resendIn
  } = useSignInForm();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Icon name="calo" width={320} height={256} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box>
          <MuiTelInput
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            value={values.username}
            onChange={(e) => {
              handleChange('username')(removeAllWhiteSpacesAndDashes(e));
            }}
            error={Boolean(errors.username)}
            autoFocus
            onlyCountries={countryCodes}
            defaultCountry="BH"
          />
          {values.codeSent && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="otpCode"
              label="OTP Code"
              id="otpCode"
              placeholder="123456"
              error={Boolean(errors.code)}
              value={values.code}
              onChange={handleChange('code')}
              onBlur={handleBlur('code')}
            />
          )}
          <Button
            onClick={submitForm}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading || !isValid || isSubmitting || (values.codeSent && values.code.length !== 6)}
          >
            Sign In
          </Button>
          {values.codeSent && <ResendButton handleResend={handleResend} resendIn={resendIn} />}
        </Box>
        {loading && <LoadingCircle />}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          sx={{ opacity: '25%', position: 'fixed', fontSize: '10px', bottom: 0, textAlign: 'center', marginBottom: 1 }}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </Typography>
      </Box>
    </Container>
  );
};

export default SignInForm;

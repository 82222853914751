import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { CognitoUser } from '@aws-amplify/auth';
import { validatePhoneNumberLength } from 'libphonenumber-js';

import { resendCode, signIn, verifyCode } from '../../lib/auth/helpers';

interface SignInValues {
  username: string;
  code: string;
  codeSent: boolean;
} 

let isRequestingOTP = false;
let cognitoUser: CognitoUser | null;

export default () => {
  const [resendIn, setResendIn] = useState<null | number>(null);
  const [loading, setLoading] = useState<boolean | undefined>();

  const handleResend = async () => {
    const data = cognitoUser && (await resendCode(cognitoUser));
    if (data) {
      setResendIn(data.resendIn);
    }
  };

  useEffect(() => {
    if (!resendIn) {
      return;
    }

    const timer = setInterval(() => {
      if (resendIn - 1 === 0) {
        setResendIn(() => null);
      } else {
        setResendIn((prevResend) => prevResend && prevResend - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [resendIn]);

  const sendSignInOTP = async (username: string) => {
    if (!isRequestingOTP) {
      isRequestingOTP = true;
      const data = await signIn(username, 'OTP');
      if (data && !resendIn) {
        setResendIn(data.resendIn);
      }
      cognitoUser = data.cognitoUser;
      return true;
    }
    return false;
  };

  const form = useFormik<SignInValues>({
    initialValues: {
      username: '',
      code: '',
      codeSent: false
    },
    validateOnMount: true,

    validate: (values: SignInValues) => {
      const errors: any = {};
      if (validatePhoneNumberLength(values.username)) {
        errors.username = true;
      }
      return errors;
    },
    onSubmit: async (values, { setFieldValue, setSubmitting, setFieldError, resetForm }) => {
      try {
        setLoading(true);

        if (values.codeSent) {
          cognitoUser && (await verifyCode(values.code, cognitoUser, 'OTP'));
          cognitoUser = null;
        } else {
          if (await sendSignInOTP(values.username)) {
            isRequestingOTP = false;
            setFieldValue('codeSent', true);
            setLoading(false);
          }
        }
      } catch (error) {
        console.debug({ error });
        setSubmitting(false);
        setLoading(false);
        isRequestingOTP = false;
        switch (error.message) {
          case 'INVALID_CODE':
            setFieldError('code', 'true');
            break;
          default:
            if (error.code === 'UserNotFoundException') {
              setFieldError('username', 'Phone number not found');
            } else if (error.code === 'NotAuthorizedException') {
              setFieldError('username', 'Please wait to retry OTP');
            } else {
              resetForm();
            }
        }
      }
    }
  });

  const resendInPadded = useMemo(
    () =>
      resendIn &&
      `${Math.floor(resendIn / 60)
        .toString()
        .padStart(2, '0')}:${(resendIn % 60).toString().padStart(2, '0')}`,
    [resendIn]
  );

  return {
    handleResend,
    resendIn: resendInPadded,
    loading,
    ...form
  };
};

import Auth from '@aws-amplify/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface useAuthProps {
  userId?: string;
  setUserId: (userId?: string) => void
}

export const useAuth = ({ userId, setUserId }: useAuthProps) => {
  const navigate = useNavigate();

  
  useEffect(() => {
    const authenticate = async () => {
      if (userId) {
        navigate(`/${userId}`);
      } else {
        try {
          const authenticatedUser = await Auth.currentAuthenticatedUser();
          setUserId(authenticatedUser.attributes.sub);
        } catch {
          navigate('/');
        }
      }
    };
    authenticate();
  }, [navigate, userId, setUserId]);
};

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import 'typeface-roboto';

import App from './App'; 
import './index.css'

Auth.configure({
  region: 'us-east-1',
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  endpoint: process.env.REACT_APP_AUTH_ENDPOINT
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

import { Box } from '@mui/material';
import ReactLoading from 'react-loading';

const CaloLoader = () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <svg width="73" height="44" viewBox="0 0 73 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.4035 12H16.7462C16.9087 12.0092 17.0706 12.0243 17.232 12.0453C19.424 12.3317 21.1192 14.3272 21.1284 16.5737C21.1316 17.3426 21.1321 18.1106 21.1299 18.8779C21.1299 18.8809 21.1288 18.8837 21.1267 18.8858C21.1247 18.8879 21.1219 18.8891 21.119 18.8891L18.1443 18.8877C18.1424 18.8877 18.1406 18.8869 18.1393 18.8856C18.138 18.8842 18.1372 18.8824 18.1372 18.8805C18.1372 18.1739 18.1372 17.4673 18.1372 16.7608C18.1372 16.5559 18.1306 16.4105 18.1173 16.3247C18.0014 15.5778 17.3809 14.9934 16.6442 14.9214C15.791 14.8384 15.0077 15.4306 14.8579 16.2993C14.8415 16.3946 14.8333 16.5398 14.8333 16.7348C14.8332 20.1889 14.833 23.6429 14.8329 27.0969C14.8327 27.3055 14.8378 27.4511 14.8481 27.5336C14.9644 28.4789 15.8037 29.1191 16.7293 28.9831C17.4171 28.8823 17.9738 28.3381 18.1081 27.6512C18.1271 27.5535 18.1367 27.4083 18.1368 27.2157C18.1374 26.358 18.1375 25.5003 18.137 24.6425C18.137 24.6397 18.1381 24.6369 18.1401 24.6349C18.1421 24.6329 18.1447 24.6318 18.1475 24.6318H21.113C21.1153 24.6318 21.1176 24.6323 21.1197 24.6332C21.1219 24.6341 21.1238 24.6354 21.1254 24.6371C21.1271 24.6388 21.1284 24.6408 21.1292 24.643C21.1301 24.6452 21.1306 24.6476 21.1306 24.6499C21.1309 25.5652 21.1309 26.481 21.1306 27.3976C21.1299 28.9392 20.3993 30.2768 19.1903 31.1636C18.2461 31.856 17.1097 32.102 15.962 31.9625C13.8302 31.703 12.1422 29.8782 12.0099 27.6857C12.0043 27.5932 12.0014 27.4474 12.0013 27.2484C11.9996 23.709 11.9996 20.1694 12.0013 16.6297C12.0017 15.7365 12.2414 14.8613 12.7266 14.1234C13.4643 13.0016 14.6043 12.2162 15.9189 12.0429C16.0654 12.0236 16.2269 12.0093 16.4035 12Z"
        fill="#28B17B"
      />
      <path
        d="M53.207 30.64C52.2773 29.6828 51.8707 28.5721 51.8703 27.2376C51.8679 21.2784 51.8679 17.7424 51.8703 16.6297C51.8725 15.6318 52.1801 14.6703 52.7738 13.8806C53.9083 12.3724 55.6453 11.7219 57.4718 12.1236C58.3595 12.3188 59.1467 12.8105 59.7716 13.4947C60.5147 14.3086 60.9209 15.2612 60.99 16.3525C60.9961 16.4501 60.9993 16.5957 60.9994 16.7893C61.0003 20.3017 61.0002 23.814 60.9992 27.3263C60.9988 28.7313 60.4527 29.9371 59.4248 30.8617C58.5026 31.6912 57.3705 32.0548 56.1472 31.9929C55.0117 31.9354 54.0122 31.4693 53.207 30.64ZM55.1554 28.46C55.585 28.905 56.1069 29.0636 56.7213 28.9359C57.3981 28.7951 57.8947 28.191 57.9761 27.5117C57.9858 27.4311 57.9906 27.2856 57.9906 27.0751C57.9906 23.6464 57.9904 20.2178 57.99 16.7893C57.99 16.5863 57.9833 16.4409 57.9699 16.3531C57.8613 15.6447 57.2945 15.0695 56.5985 14.9727C55.595 14.8334 54.6927 15.5813 54.6919 16.6295C54.691 17.8681 54.6925 21.4283 54.6964 27.3099C54.6966 27.76 54.8497 28.1434 55.1554 28.46Z"
        fill="#28B17B"
      />
      <path
        d="M28.6169 27.2895L27.9194 31.741C27.9188 31.7446 27.917 31.7479 27.9143 31.7502C27.9116 31.7526 27.9082 31.7539 27.9047 31.7539H24.9261C24.9248 31.7539 24.9236 31.7536 24.9224 31.753C24.9213 31.7525 24.9203 31.7517 24.9195 31.7507C24.9186 31.7497 24.918 31.7485 24.9177 31.7473C24.9174 31.746 24.9173 31.7447 24.9175 31.7434L28.1516 12.2565C28.1521 12.2536 28.1536 12.2511 28.1558 12.2493C28.1579 12.2474 28.1606 12.2464 28.1634 12.2464L32.1185 12.2471C32.1216 12.2471 32.1245 12.2481 32.1267 12.2501C32.129 12.2521 32.1305 12.2548 32.131 12.2578L35.3235 31.7443C35.3237 31.7454 35.3236 31.7466 35.3233 31.7477C35.323 31.7489 35.3225 31.7499 35.3218 31.7508C35.321 31.7517 35.3201 31.7524 35.3191 31.7529C35.3181 31.7534 35.3169 31.7537 35.3158 31.7537L32.3135 31.753C32.3105 31.753 32.3076 31.7519 32.3053 31.7499C32.303 31.7479 32.3015 31.7451 32.301 31.7421L31.6259 27.3016C31.6247 27.2938 31.6208 27.2867 31.6149 27.2816C31.6091 27.2765 31.6017 27.2736 31.594 27.2736C30.8901 27.2714 30.1903 27.271 29.4945 27.2725C29.2073 27.2731 28.9352 27.2659 28.6293 27.2782C28.6263 27.2783 28.6234 27.2795 28.6212 27.2816C28.6189 27.2836 28.6174 27.2865 28.6169 27.2895ZM30.1194 16.7746L29.0618 24.3891C29.0616 24.3907 29.0617 24.3924 29.0621 24.3939C29.0625 24.3955 29.0633 24.397 29.0643 24.3982C29.0654 24.3995 29.0667 24.4005 29.0681 24.4011C29.0696 24.4018 29.0711 24.4022 29.0727 24.4022H31.1827C31.1838 24.4022 31.1849 24.402 31.1859 24.4015C31.1869 24.401 31.1879 24.4004 31.1886 24.3995C31.1894 24.3986 31.1899 24.3976 31.1903 24.3965C31.1906 24.3954 31.1907 24.3942 31.1906 24.393C30.8412 21.8826 30.4937 19.3711 30.1483 16.8586C30.1447 16.8326 30.137 16.8044 30.1251 16.7739C30.1223 16.7671 30.1204 16.7673 30.1194 16.7746Z"
        fill="#28B17B"
      />
      <path
        d="M42.7929 28.8283L47.7456 28.8292C47.7484 28.8292 47.7511 28.8303 47.7531 28.8324C47.7551 28.8344 47.7563 28.8372 47.7563 28.8401L47.7556 31.7425C47.7556 31.7454 47.7545 31.7482 47.7525 31.7503C47.7506 31.7523 47.7479 31.7535 47.7451 31.7535L39.9058 31.7537C39.903 31.7537 39.9003 31.7526 39.8984 31.7506C39.8964 31.7485 39.8953 31.7458 39.8953 31.743V12.2571C39.8953 12.2557 39.8956 12.2543 39.8961 12.253C39.8966 12.2517 39.8974 12.2505 39.8984 12.2495C39.8993 12.2485 39.9005 12.2478 39.9018 12.2472C39.903 12.2467 39.9044 12.2464 39.9058 12.2464L42.771 12.2471C42.7738 12.2471 42.7765 12.2482 42.7784 12.2503C42.7804 12.2524 42.7815 12.2552 42.7815 12.2582L42.7817 28.8167C42.7817 28.8183 42.782 28.8198 42.7826 28.8212C42.7831 28.8226 42.784 28.8238 42.785 28.8249C42.786 28.826 42.7873 28.8269 42.7886 28.8274C42.79 28.828 42.7914 28.8283 42.7929 28.8283Z"
        fill="#28B17B"
      />
    </svg>
    <ReactLoading type="bubbles" color="#57AE7F" width={100} height={75} />
  </Box>
);

export default CaloLoader;
